import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdlMembresia } from './models/MdlMembresia';
import { GeneralService } from './services/general.service';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  public formulario: FormGroup = new FormGroup({});
  public format: string = 'dd/MM/yyyy';
  // listbox
  public lstEstadoBox: any;
  public lstMunicipioBox: any;
  public lstPaisBox: any;
  public lstClasificaBox: any;
  public lstEstatusBox: any;
  public clientenombre: any;
  // config listbox
  public fields: Object = { text: 'descripcion', value: 'id' };
  public usuario: MdlUsuario;
  public MdlMembresia: MdlMembresia;
  public id: Number = -1;

  public 

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: GeneralService,


  ) {
    // lisbox carga de informacion
    this.Services.lstMunicipioBox().subscribe(resp => { this.lstMunicipioBox = resp.respuesta; });
    this.Services.lstEstadoBox().subscribe(resp => { this.lstEstadoBox = resp.respuesta; });
    // this.Services.lstPaisBox().subscribe(resp => { this.lstPaisBox = resp.respuesta; });
    this.Services.lstClasificaBox().subscribe(resp => { this.lstClasificaBox = resp.respuesta; });
    this.Services.lstEstatusBox().subscribe(resp => { this.lstEstatusBox = resp.respuesta; });
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.Services.getCliente(this.usuario.id_clb_membresia).subscribe(resp => {
      this.clientenombre = resp.respuesta.nombre;
    });
    this.id = Number(this.usuario.id_clb_membresia);
    //************************************************************************************************************************************ */
    // Fomulario => instanciamos el formulario con el modelo
    this.formulario = this.formBuilder.group({
      id: [-1],
      nombre:  [''],
      paterno: [''],
      materno: [''],
      calle:   [''],
      colonia: [''],
      num_ext: [''],
      num_int: [''], 
      id_clb_membresia_clasifica: [-1],
      correo:  [''],
      tel_fax:  [''],
      tel_oficina: [''],
      tel_movil: [''],
      cp:  [''],
      rfc: [''],
      id_clb_membresia_estatus: [-1],
      id_municipio:    [-1],
      codigo:    [-1],
      fecha_ingreso: [''],
      fecha_nacimiento: [''],
      id_cliente: [-1],
      fecha_vence: [''],
      twitter: [''],
      clave: [''],
      whatsapp: [''],
      referenciabancaria: [''],
      dispositivo_domicilio: [''],
      dispositivo_agua: [''],
    });
  }
  ngOnInit(): void {
    this.Services.getMembresia(Number(this.id)).subscribe(resp => {
      this.formulario = this.formBuilder.group(JSON.parse(JSON.stringify(resp.respuesta)) as MdlMembresia);
    });

         // id de la membresia localStorage se debe importar modelo en consola
     let id = JSON.parse(localStorage.getItem('usuario'));
     id.id
         console.log(id.id_clb_membresia);

  }
}
