import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dialog, DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CancelacionesService } from './Services/Cancelaciones.service';

@Component({
  selector: 'app-cancelaciones',
  templateUrl: './cancelaciones.component.html',
  styleUrls: ['./cancelaciones.component.scss']
})
export class CancelacionesComponent implements OnInit {
  public data: any;
  public reservacion: any;
  UtilComponent: any;
  //************************************************************************************************************************************ */
  //  formularios: formularios utilzados 
  public frmCancelacion: FormGroup = new FormGroup({});
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('dlgCancelacion') dlgCancelacion: DialogComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private service: CancelacionesService,
  ) {
    this.route.data.subscribe((resp: { reservacion: any[] }) => {
      this.data = JSON.parse(JSON.stringify(resp.reservacion)).respuesta.dataSource;
      this.reservacion = JSON.parse(JSON.stringify(resp.reservacion)).respuesta.dataSource
    });

    this.frmCancelacion = this.fb.group({
      id: [-1],
      id_clb_reserva: [-1, [Validators.required, Validators.min(-1)]],
      id_doc_estatus: [3, [Validators.required, Validators.min(-1)]],
      id_rh_empleado: [331, [Validators.required, Validators.min(-1)]],
      id_doc_estatus_causa: [5, [Validators.required, Validators.min(-1)]],
    });
  }

  ngOnInit(): void {
  }

  //****************************************************************** */
  //Metodo: Cancelar reservaciones 
  public DialogoCancelacion(args: any) {
    this.frmCancelacion.controls['id_clb_reserva'].setValue(Number(args.id));
    this.reservacion= args;
    this.dlgCancelacion.show();
  }
  public MsjCancelacion() {
    this.UtilComponent = DialogUtility.confirm({
      title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<b>' + "Estas Seguro de Cancelar Reservación ?" + '</b>',
      okButton: { text: 'Si', click: this.cancelar.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' }
    });
  }
  public cancelar(componente: Dialog) {
    this.UtilComponent.hide();
    this.dlgCancelacion.hide();
    this.blockUI.start('trabajando...');
    this.service.Guardar(this.frmCancelacion.value).subscribe(
      resp => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        if (respuesta.numero < 0) {
          this.blockUI.stop();
          DialogUtility.alert({
            title: '<span class="dialogo-titulo-error">' + respuesta.titulo + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
            content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        }
        else {
          this.dlgCancelacion.hide();
          this.blockUI.stop();
          DialogUtility.alert({
            title: respuesta.titulo + '<span style="color: #28a745">' + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
          /**
           * sacamos el id desde localstorage
           */
          let id = JSON.parse(localStorage.getItem('usuario'));
          id.id_clb_membresia
          this.service.TBcancelaciones(id.id_clb_membresia).subscribe((resp) => {
            this.data = resp.respuesta.dataSource;
          });
          this.frmCancelacion = this.fb.group({
            id: [-1],
            id_clb_reserva: [-1, [Validators.required, Validators.min(-1)]],
            id_doc_estatus: [3, [Validators.required, Validators.min(-1)]],
            id_rh_empleado: [331, [Validators.required, Validators.min(-1)]],
            id_doc_estatus_causa: [5, [Validators.required, Validators.min(-1)]],
          });
        }
      }
    );
  }
}
