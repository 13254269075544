import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { ConfirmacionpagoService } from './Services/Confirmacionpago.service';

@Component({
  selector: 'app-confirma-pago',
  templateUrl: './confirma-pago.component.html',
  styleUrls: ['./confirma-pago.component.scss']
})
export class ConfirmaPagoComponent implements OnInit, OnDestroy{
  // public usuario: MdlUsuario;
  // public Recibo: any;
  // public ReciboDetalle: any;
  public dataFacturacion: any;
  public correo: any;
  // tomamos el id de localStorage
  // id_doc: any = localStorage.getItem('id_documento');
  constructor
  (
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: ConfirmacionpagoService
  )
  {
    this.route.data.subscribe((resp) => {
      this.dataFacturacion = resp.info.respuesta;
    });   
  }
  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    // DESTRUIMOS ID DE LOCAL
    // localStorage.removeItem('id_documento');
  }
}  

