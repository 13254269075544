import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import {
  GridComponent,
  SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { EstadocuentaService } from './services/estadocuenta.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-estadocuenta',
  templateUrl: './estadocuenta.component.html',
  styleUrls: ['./estadocuenta.component.scss'],
})
export class EstadocuentaComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public dsAdeudos: any;
  public dsHistorial: any;
  public ImporteTotal: number = 0;
  public usuario: MdlUsuario;
  @ViewChild('tbAdeudos') tbAdeudos: GridComponent;
  @ViewChild('importefinal') importefinal: ElementRef;
  @ViewChild('checkbox') checkbox: CheckBoxComponent;
  public id: number = -1;
  public selectionOptions: SelectionSettingsModel = {
    type: 'Multiple',
    enableSimpleMultiRowSelection: true,
  };
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private Services: EstadocuentaService,
    private router: Router
  ) {
    this.dsAdeudos = '';
    this.dsHistorial = '';
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.Services.getTabla(
      this.usuario.id_clb_membresia,
      true
    ).subscribe((resp) => {
      this.dsAdeudos = resp.respuesta;
    });
    this.Services.getTabla(this.usuario.id_clb_membresia, true).subscribe((resp) => {
      this.dsHistorial = resp.respuesta;
    });
  }

  ngOnInit(): void {
    this.Services.getTablaPago(this.usuario.id_clb_membresia).subscribe((resp) => {
      this.dsAdeudos = resp.respuesta;
    });
  }

  ngOnDestroy(): void {
    this.dsAdeudos = '';
    this.dsHistorial = '';
  }

  public onRowSelected(args: any) {
    this.blockUI.start('Procesando...');
    this.ImporteTotal = 0.0;
    let registros: any = this.tbAdeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.adeudo2;
    });
    this.importefinal.nativeElement.value = this.ImporteTotal;
    this.blockUI.stop();
  }

  /**
   *
   * @param args
   */
  public rowDeselected(args: any): void {
    this.blockUI.start('Procesando...');
    this.ImporteTotal = 0.0;
    let registros: any = this.tbAdeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.adeudo2;
    });
    this.blockUI.stop();
  }

  // function to handle the CheckBox change event
  public changeHandler(): void {
    if (this.checkbox.checked === true) {
      this.Services.getTabla(this.usuario.id_clb_membresia, false).subscribe((resp) => {
        this.dsHistorial = resp.respuesta;
      });
    }
    this.dsHistorial = '';
  }

  btnPagar() {
    this.blockUI.start('Procesando...');
    let registros: any = this.tbAdeudos.getSelectedRecords();
    let ids: string = '';
    registros.forEach((registro) => {
      ids += ',' + registro.id;
    });
    this.Services.getAdeudo(String(this.usuario.id_clb_membresia), ids).subscribe(
      (resp) => {
        if (resp.numero > 0) {
          this.router.navigate(['pages/openpay', resp.numero]);
        } else {
          this.blockUI.stop();
          DialogUtility.alert({
            title: '<span style="color: #28a745">' + resp.titulo + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        }
      }
    );
  }
}
